import request from '@/utils/request'
// 商户列表
export function getCommercialList(data) {
  return request('post', '/aps/merchant/merchantList', data)
}

// 新增修改商户
export function getCommercialEdit(data) {
  return request('post', '/aps/merchant/createOrUpdate', data)
}

// 删除商户
export function getCommercialDel(data) {
  return request('post', '/aps/merchant/merchantDel', data)
}

// 启停商户
export function getCommercialStop(data) {
  return request('post', '/aps/merchant/startOrStop', data)
}

// 商户列表（当前权限）
export function getAutoCommercialList() {
  return request('get', '/aps/merchant/accountMerList')
}