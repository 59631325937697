<!--门店余额出账统计-->
<template>
  <div class="balanceCount">
    <section class="table-search">
      <div style="display: flex">
        <div class="search-L">
          <el-select
            v-model="tableParams.merchantId"
            placeholder="请选择商户"
            @change="onMerchantSel"
            clearable
            class="mr15"
          >
            <el-option
              v-for="item in commercialList"
              :key="item.merchantId"
              :label="item.merchantName"
              :value="item.merchantId"
            >
            </el-option>
          </el-select>
          <el-select
            v-model="tableParams.shopId"
            placeholder="请选择店铺"
            @change="onShopSel"
            clearable
            class="mr15"
          >
            <el-option
              v-for="item in tableShopList"
              :key="item.shopId"
              :label="item.shopName"
              :value="item.shopId"
            >
            </el-option>
          </el-select>
        </div>
        <el-date-picker
          class="mr20"
          v-model="tableParams.queryMonth"
          type="month"
          placeholder="选择月"
          :pickerOptions="pickerOptions"
          @change="goChangeMonth"
        >
        </el-date-picker>
      </div>

      <div class="search-R">
        <el-button
          type="primary"
          :disabled="tableData && tableData.length == 0"
          @click="goClick(0)"
          >导出</el-button
        >
      </div>
    </section>
    <!-- 表格 -->
    <el-table
      :data="tableData"
      border
      stripe
      :header-cell-style="{
        background: '#FAFAFA',
        color: '#000000',
      }"
    >
      <el-table-column label="门店名称" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.shopName }}</span>
        </template>
      </el-table-column>
      <el-table-column label="出账状态" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.billStatus ? "已出账" : "待出账" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="充值消费统计" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.rechargeTotalAmount }}</span>
        </template>
      </el-table-column>
      <el-table-column label="出账总额" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.splitBillTotalAmount }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button
            type="text"
            size="medium"
            @click.stop="goClick(1, scope.row.shopId)"
            >查看详情</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="table-footer">
      <el-pagination
        layout="total,sizes, prev, pager, next,jumper"
        background
        :total="total"
        :page-size="tableParams.pageSize"
        @size-change="onSizeChange"
        :page-sizes="[10, 20, 50, 100]"
        :current-page.sync="currentPage"
        @current-change="onCurrentPage"
      >
      </el-pagination>
    </div>
    <!--查看详情-->
    <el-dialog
      :visible.sync="editShow"
      title="查看详情"
      :close-on-click-modal="false"
      width="840px"
      custom-class="add-dialog"
    >
      <section class="table-search">
        <div></div>

        <el-button
          type="primary"
          :disabled="itemTableData && itemTableData.length == 0"
          @click="goClick(2)"
          >导出</el-button
        >
      </section>
      <el-table
        :data="itemTableData"
        border
        stripe
        :header-cell-style="{
          background: '#FAFAFA',
          color: '#000000',
        }"
      >
        <el-table-column label="交易时间" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.addDt }}</span>
          </template>
        </el-table-column>
        <el-table-column label="用户" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.nickName }}| {{ scope.row.mobile }}</span>
          </template>
        </el-table-column>
        <el-table-column label="订单类型" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.businessTypeName }}</span>
          </template>
        </el-table-column>
        <el-table-column label="订单内容" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.description }}</span>
          </template>
        </el-table-column>
        <el-table-column label="实付金额" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.splitAmount }}</span>
          </template>
        </el-table-column>
      </el-table>
      <div class="table-footer">
        <el-pagination
          layout="total,sizes, prev, pager, next,jumper"
          background
          :total="itemTotal"
          :page-size="itemParams.pageSize"
          @size-change="onItemSizeChange"
          :page-sizes="[10, 20, 50, 100]"
          :current-page.sync="itemCurrentPage"
          @current-change="onItemCurrentPage"
        >
        </el-pagination>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { getAutoCommercialList } from "@/api/commercial/commercial";
import { getIdShopList } from "@/api/commercial/shop";
import {
  getBalancePage,
  getBalanceDetail,
  getBalancePageToExcel,
  getBalanceDetailToExcel,
} from "@/api/finances/balanceCount.js";
import { formatterDate } from "@/utils/utils.js";
export default {
  name: "BalanceCount",
  data() {
    return {
      formatterDate,
      commercialList: [], // 商户列表
      tableShopList: [], //店铺列表
      // 列表
      currentPage: 1, // 当前页
      total: 0, // 总条数
      tableData: [],
      tableParams: {
        page: 1,
        pageSize: 10,
        merchantId: "", //商户ID
        shopId: "", //店铺ID
        queryMonth: new Date(), // 选择月份
      },
      editShow: false,
      itemTableData: [],
      itemParams: {
        page: 1,
        pageSize: 10,
      },
      itemCurrentPage: 1, // 当前页
      itemTotal: 0, // 总条数
      pickerOptions: {
        disabledDate: (time) => {
          return time.getTime() > Date.now();
        },
      },
      itemShopId: "",
    };
  },
  mounted() {
    this.getAutoCommercialList(); // 【请求】商户下拉列表
    this.initMonth();
  },
  methods: {
    // 初始化月份
    initMonth() {
      let date = new Date();

      date.setDate(0);
      let y = date.getFullYear(); //获取年份

      let m = date.getMonth() + 1; //获取月份
      m = m > 10 ? m : "0" + m;
      this.tableParams.queryMonth = [y, m].join("-");
    },
    goChangeMonth() {
      this.tableParams.queryMonth = this.formatterDate(
        this.tableParams.queryMonth,
        4
      );
      this.getBalancePage();
    },
    // 余额账单导出
    getBalancePageToExcel() {
      let params = {
        merchantId: this.tableParams.merchantId,
        shopId: this.tableParams.shopId,
        queryMonth: this.tableParams.queryMonth,
      };
      let name = this.tableParams.queryMonth + "余额账单";
      getBalancePageToExcel(params, name);
    },
    // 余额账单明细导出
    getBalanceDetailToExcel() {
      let params = {
        merchantId: this.tableParams.merchantId,
        shopId: this.itemShopId,
        queryMonth: this.tableParams.queryMonth,
      };
      let name = this.tableParams.queryMonth + "余额账单明细";
      getBalanceDetailToExcel(params, name);
    },
    // 余额账单查询
    getBalancePage() {
      let params = {
        merchantId: this.tableParams.merchantId,
        shopId: this.tableParams.shopId,
        page: this.tableParams.page,
        pageSize: this.tableParams.pageSize,
        queryMonth: this.tableParams.queryMonth,
      };
      getBalancePage(params).then((res) => {
        if (res.isSuccess == "yes") {
          this.tableData = res.data.list;
          this.total = res.data.total;
        }
      });
    },
    // 余额账单详情
    getBalanceDetail() {
      let params = {
        merchantId: this.tableParams.merchantId,
        shopId: this.itemShopId,
        page: this.itemParams.page,
        pageSize: this.itemParams.pageSize,
        queryMonth: this.tableParams.queryMonth,
      };
      getBalanceDetail(params).then((res) => {
        if (res.isSuccess == "yes") {
          this.itemTableData = res.data.list;
          this.itemTotal = res.data.total;
          this.editShow = true;
        }
      });
    },
    // 点击
    goClick(val, shopId) {
      switch (val) {
        case 0:
          this.getBalancePageToExcel();
          break;
        case 1:
          this.itemShopId = shopId;
          this.getBalanceDetail();
          break;
        case 2:
          this.getBalanceDetailToExcel();
          break;
      }
    },
    // 【请求】商户下拉列表
    getAutoCommercialList() {
      getAutoCommercialList().then((res) => {
        if (res.isSuccess == "yes") {
          this.commercialList = res.data;
        }
      });
    },

    // 【请求】店铺列表
    getIdShopList() {
      let data = {
        merchantId: this.tableParams.merchantId,
      };
      getIdShopList(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.tableShopList = res.data;
        }
      });
    },
    // 【监听】筛选商户
    onMerchantSel() {
      if (this.tableParams.merchantId) {
        this.getIdShopList();
      } else {
        this.tableShopList = [];
      }
    },

    // 【监听】筛选店铺
    onShopSel() {
      this.reload();
    },
    // 【监听】表格重载
    reload() {
      this.tableParams.page = 1;
      this.currentPage = 1;
      this.getBalancePage();
    },

    // 【监听】表格分页点击
    onCurrentPage(page) {
      this.tableParams.page = page;
      this.getBalancePage();
    },

    // 【监听】表格条数点击
    onSizeChange(pageSize) {
      this.tableParams.pageSize = pageSize;
      this.reload();
    },

    // 【监听】表格分页点击
    onItemCurrentPage(page) {
      this.itemParams.page = page;
      this.getBalanceDetail();
    },

    // 【监听】表格条数点击
    onItemSizeChange(pageSize) {
      this.itemParams.pageSize = pageSize;
      this.getBalanceDetail();
    },
  },
};
</script>
<style lang="scss" scoped>
.balanceCount {
  padding: 0.15rem;
  box-sizing: border-box;
}

.table-search {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.15rem;
}

.search-L {
  display: flex;
}

.table-footer {
  margin-top: 0.1rem;
  display: flex;
  justify-content: flex-end;
}
</style>