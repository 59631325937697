import request from "@/utils/request";
import Export from "@/utils/export";
//余额账单查询
export function getBalancePage(data) {
  return request("post", "/apm/request/balancePage", data);
}

//余额账单明细查询
export function getBalanceDetail(data) {
  return request("post", "/apm/request/balanceDetailsPage", data);
}

//余额账单导出
export function getBalancePageToExcel(data,name) {
  return Export("post", "/apm/request/balanceExcel", data,name);
}
//余额账单明细导出
export function getBalanceDetailToExcel(data,name) {
  return Export("post", "/apm/request/balanceDetailsExcel", data,name);
}
