import request from "@/utils/request";
// 店铺列表
export function getShopList(data) {
  return request("post", "/aps/shop/shopList", data);
}

// 新增修改店铺
export function getShopEdit(data) {
  return request("post", "/aps/shop/createOrUpdate", data);
}

// 删除店铺
export function getShopDel(data) {
  return request("post", "/aps/shop/shopDel", data);
}

// 启停店铺
export function getShopStop(data) {
  return request("post", "/aps/shop/startOrStop", data);
}

// 店铺列表（通过商户id）
export function getIdShopList(data) {
  return request("post", "/aps/shop/shopListByMer", data);
}

// 查询门店个性化设置
export function getShopSetting(data) {
  return request("post", "/apm/shop/personalization/findById", data);
}

// 保存门店个性化设置
export function goSaveShopSetting(data) {
  return request("post", "/apm/shop/personalization/saveOrUpdate", data);
}
